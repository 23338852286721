export function DogeIcon({ bgColor, ...rest }: {
  className?: string;
  bgColor: string;
  width?: number;
}) {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 19 24"
    >
      <path
        fill="currentColor"
        d="M1.894 10.863c-.127.824-.687 3.435-.951 4.638-.195.896-.288 2.005-.066 2.332.575.844 1.717 1.423 2.71 2.132 1.028.733 3.478 2.098 4.999 2.881s3.123-.323 3.691-.593c.616-.291 4.552-2.19 5.828-3.011 1.156-.743.664-1.676.573-2.139-.201-1.015-.325-1.852-.96-6.45-.634-4.6-1.091-4.764-1.884-6.191s-3.38-3.175-4.371-3.77c-.992-.594-2.016-.758-3.56.262-1.448.957-2.797 2.812-3.471 3.764-.674.951-2.38 5.114-2.538 6.145"
      />
      <path
        fill={bgColor}
        d="M6.892 19.122c-.987-1.095-1.809-3.16-2.096-4.056-1.054-2.903.27-3.464.592-3.718.32-.253 1.504-.604 2.349.254.84.852 1.307.81 2.054.884.638.064 2.316-.935 2.738-1.138s1.564-.17 2.477.59c.912.761.51 2.297.211 3.685-.274 1.275-1 2.454-2.115 3.908s-2.278 1.692-3.311 1.618c-1.068-.078-1.666-.659-2.9-2.027"
      />
      <path
        fill="currentColor"
        d="M10.192 19.933c-.691.09-1.636-.356-2.062-.764-.594-.567-.701-1.202-.724-1.605 0-.362-.058-.96.351-1.453.626-.754 2.151-.603 3.034-.545.882.057 1.62.783 1.721 1.322a2.9 2.9 0 0 1-.378 2.005c-.446.738-1.496.983-1.942 1.04"
      />
    </svg>
  );
}